import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

import { enquireScreen } from "enquire-js";
import delCheck from "../../../assets/imgs/delCheck.png";
import delCheckBox from "../../../assets/imgs/delCheckBox.png";
import "./index.less";
import "./index-Pc.less";

const phoneLimit = "Número de @num dígitos, que comienzan con 9";
const curpLimit = "Número de 18 dígitos + Mayúsculas";
export class DeleteUser extends React.PureComponent {
  state = {
    hasSubmit: false,
    textInfo: null,
    textFocus: false,
    inputFocus: false,
    textPhone: null,
    message: null,
    hasCommit: false,
    showModal: false,
    idCardType: 2,
    idCard: null,
    idCardTypeDesc: null,
    idCardError: null,
    idCardFocus: false,
    agreeCheck: true,
    isMobile: false,
    showError: false,
    errorMsg: null,
  };
  componentDidMount() {
    const stateTempJSON = localStorage.getItem("stateTemp");
    if (stateTempJSON) {
      this.setState({ ...JSON.parse(stateTempJSON) });
      setTimeout(() => {
        localStorage.removeItem("stateTemp");
      }, 500);
    }
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
  }
  initIdCardRule = (idCardType) => {
    const idCard = {
      len: 20,
      maxLen: 20,
      min: 20,
      max: 20,
      rule: /^[A-Za-z]{4}-[0-9]{6}-[0-9A-Za-z]{8}$/,
      msg: curpLimit,
    };
    return idCard;
  };
  handleFocusText = (flag) => {
    this.setState({ textFocus: flag });
  };
  buscarChangeText = (e) => {
    const { value } = e.target;
    this.setState({ textInfo: value });
  };
  buscarChangePhone = (e) => {
    console.log("");
    const { value } = e.target;
    const textPhone = value.replace(/[^0-9-]/g, "");
    // this.props.form.setFieldsValue({phone: textPhone})
    let errorMsg = null;
    const phonePattern = /^[0-9]{10}$/;
    errorMsg = !phonePattern.test(textPhone)
      ? phoneLimit.replace(/@num/g, 10)
      : null;

    this.setState({ textPhone, message: errorMsg });
  };
  buscarChangeIdCard = (e) => {
    const { value } = e.target;
    const { len, min, max, rule, msg } = this.initIdCardRule();

    const idCard = value;
    console.log("value", value, idCard);
    // this.props.form.setFieldsValue({idCard})
    let errorMsg = null;
    let vLen = String(idCard || "").trim().length;
    if (vLen === 0) {
    } else {
      // const {len, min, max} = this.initIdCardRule(idCardType)
      if (len && vLen !== len) {
        // errorMsg = lenLimit.replace(/@len/g, len + "");
        errorMsg = msg;
      }
      if (min && max) {
        if (min > vLen || max < vLen) {
          // errorMsg = rangeLimit
          //   .replace(/@max/g, max + "")
          //   .replace(/@min/g, min + "");
          errorMsg = msg;
        }
      } else {
        if (min && min > vLen) {
          // errorMsg = lenLimit + " >= " + min;
          errorMsg = msg;
        }
        if (max && max < vLen) {
          // errorMsg = lenLimit + " <= " + max;
          errorMsg = msg;
        }
      }
      if (!errorMsg && !rule.test(idCard)) {
        errorMsg = msg;
      }
    }

    console.log("checkValue", idCard, errorMsg);
    this.setState({ idCardError: errorMsg });
    this.setState({ idCard });
  };
  buscarKeyup = (event: any) => {
    const {
      target: { value },
    } = event;
    let upValue = value.toUpperCase();
    let curp = "";
    let values = upValue.split("-");
    if (values && values.length > 1) {
      if (values.length === 2) {
        curp = values[0] + "-";
        let moreValue = values[1];
        if (moreValue.length > 6) {
          curp += moreValue.substring(0, 6) + "-" + moreValue.substring(6);
        } else {
          curp += moreValue;
        }
      } else {
        curp = upValue;
      }
    } else {
      if (upValue && upValue.length > 4) {
        curp = upValue.substring(0, 4) + "-";
        if (upValue.length > 10) {
          curp += upValue.substring(4, 10) + "-" + upValue.substring(10);
        } else {
          curp += upValue.substring(4, 10);
        }
      } else {
        curp = upValue;
      }
    }
    this.setState({ idCard: curp });
  };
  handleSumit = async () => {
    const {
      textPhone,
      textInfo,
      idCard,
      message,
      idCardError,
      agreeCheck,
      hasCommit,
    } = this.state;
    if (hasCommit || message || idCardError || !agreeCheck) {
      return;
    }
    const idCardTypeCheck = !idCard || (idCard && !idCardError);
    if (textPhone && idCardTypeCheck) {
      this.setState({ showError: true, errorMsg: null });
      let payload = {
        idCardType: !!idCard ? 1 : null,
        idCard,
        reason: textInfo,
        phone: textPhone,
        appId: 3,
      };
      const res = await this.getData(payload);
      console.log("res", res);
      if (res && res.ret === 0) {
        this.setState({});
        this.setState({ hasCommit: false, showError: false, hasSubmit: true });
      } else if (res && res.ret !== 0) {
        this.setState({ errorMsg: res.msg });
        // notification.error({
        //   message: res.msg,
        //   duration: 3,
        // })
      }
    }
  };
  handleOK = () => {
    this.setState({ showError: false, errorMsg: null });
  };
  checkStatus = (response) => {
    const codeMessage = {
      200: " ",
      201: " ",
      202: " ",
      204: " ",
      400: " ",
      401: " ",
      403: " ",
      404: " ",
      406: " ",
      410: " ",
      422: " ",
      500: " ",
      502: " ",
      503: " ",
      504: " ",
    };
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    const errortext = codeMessage[response.status] || response.statusText;
    const error = new Error(errortext);
    error.name = response.status;
    error.response = response;
    throw error;
  };
  getData = (payload) => {
    const res = fetch(window.location.origin + "/susDatos", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      mode: "cors",
      body: JSON.stringify(payload),
    })
      .then(this.checkStatus)
      .then((res) => {
        return res.json();
      })
      .catch((e, res) => {
        const status = e.name;
        let ret = status;
        let msg = "Falla del Sistema";

        if (status === 401) {
          msg = "Suspendido";
          return { ret, msg };
        }

        // environment should not be used
        if (status === 403) {
          msg = "Acceso Denegado";
        }
        if (status <= 504 && status >= 500) {
          msg = "Error de Sistema";
        }
        if (status >= 404 && status < 422) {
          msg = "No encontrado";
        }
        return { ret, msg };
      });
    return res;
  };

  buscarAgreeCheck = () => {
    let agreeCheck = this.state.agreeCheck;
    agreeCheck = !agreeCheck;
    this.setState({
      agreeCheck,
    });
  };
  render() {
    // const {hasSubmit,showModal,agreeCheck, isMobile, idCardType, idCardTypeDesc, idCardError, textPhone,inputFocus,idCardFocus, textFocus, message} = this.state
    const {
      hasSubmit,
      showError,
      errorMsg,
      agreeCheck,
      isMobile,

      idCardError,
      textPhone,
      inputFocus,
      idCardFocus,
      textFocus,
      message,
      idCard,
    } = this.state;

    // const desc1Split = 'Asegúrese de no tener facturas pendientes en su cuenta. ; Al eliminar la cuenta, toda su información personal asociada será borrada permanentemente y perderá el acceso a ella.'.split(';')

    const { min, max } = this.initIdCardRule();
    const mxPmTitle = "Solicitud de eliminación de cuenta";
    const mxPmTip1 = "Antes";

    const desc1Split = [
      "Asegúrese de que no hay facturas pendientes en su cuenta.;",
      "Una vez eliminada la cuenta, se eliminará toda la información personal relevante bajo su cuenta. Después de cancelarse, no podrá iniciar sesión.",
    ];
    const mxPmTip2 = "Presentación";

    const mxPmAreaCode = "+52";
    const mxPmPhone = "Celular(Cuenta de PrestMan)";
    const mxPmCard = "CURP(Enviado en PrestMan)";
    const mxPmMsg =
      "Por favor, proporcione las razones por las que desea eliminar la cuenta.";
    // const mxPmTips1 = 'Advertencia'
    // const mxPmTips2 = 'Proceso de Eliminación'
    const mxPmAgree = "Aceptar los";
    const Privacidad = "Política de privacidad";
    const Servicio = "Contrato de Usuario";
    const agreeAnd = " y ";
    const submit = "Confirmar y Recibir";
    const okText = "CONFIRMAR";
    const loadingText = "Cargando...";
    const result = "Su solicitud de eliminación ha sido enviada con éxito.";
    const privacidadUri = "/pmPolitica";
    const terminosUri = "/pmTerminos";
    let textFocusTemp = false;
    return (
      <div
        className={[
          "deleteWrapper" + (isMobile ? "-mob" : ""),
          (idCardFocus || textFocus || textFocusTemp) &&
            isMobile &&
            "edit" + (isMobile || textFocusTemp ? "-mob" : ""),
          hasSubmit && "deleteWrapperColor" + (isMobile ? "-mob" : ""),
        ].join(" ")}
      >
        <div className={"tabNav"}>
          <div className={"title"}> {mxPmTitle}</div>
        </div>
        {!hasSubmit ? (
          <>
            <div className={"content"}>
              <div className={"tip"}>{mxPmTip1}</div>

              {desc1Split.map((desc1, idx) => {
                return (
                  <div className={"desc"} key={idx}>
                    <div>{`${idx + 1}.`}</div>
                    <div>{desc1}</div>
                  </div>
                );
              })}
              <div className={"tip"}>{mxPmTip2}</div>

              <div className={"fbtInfo"}>
                <div className="fbtCol">
                  <span className="need">*</span>
                  {mxPmPhone}
                </div>
                <div
                  className={[
                    "fbtPhone",
                    "fbtTop",
                    inputFocus && "fbtContentOn",
                    message && "fbtContentError",
                  ].join(" ")}
                >
                  <div className={"fpInfo"}>
                    <div className={"fpLabel"}>{mxPmAreaCode}</div>
                    <input
                      className={"fpInput"}
                      onChange={(value) => this.buscarChangePhone(value)}
                      onKeyUp={(event) => this.buscarChangePhone(event)}
                      onFocus={() => {
                        this.setState({ inputFocus: true });
                      }}
                      onBlur={() => {
                        this.setState({ inputFocus: false });
                      }}
                      type={"tel"}
                      maxLength={10}
                    />
                  </div>
                </div>
                {!!message && (
                  <div className={["showTips"].join(" ")}>
                    <span>{message}</span>
                  </div>
                )}
                <div className="fbtCol">{mxPmCard}</div>
                <div
                  className={[
                    "fbtPhone",
                    idCardFocus && "fbtContentOn",
                    idCardError && "fbtContentError",
                  ].join(" ")}
                >
                  <div className={"fpInfo"}>
                    <input
                      className={["fpInput", "fpIdCard"].join(" ")}
                      onKeyUp={(event) => this.buscarKeyup(event)}
                      onFocus={() => {
                        this.setState({ idCardFocus: true });
                      }}
                      onBlur={() => {
                        this.setState({ idCardFocus: false });
                      }}
                      value={idCard}
                      maxLength={max}
                      minLength={min}
                      onChange={(currentValue) => {
                        this.buscarChangeIdCard(currentValue);
                      }}
                    />
                  </div>
                </div>
                {!!idCardError && (
                  <div className={["showTips"].join(" ")}>
                    <span>{idCardError}</span>
                  </div>
                )}
                <div className="fbtCol">{mxPmMsg}</div>
                <div
                  className={[
                    "fbtContent",
                    textFocus ? "fbtContentOn" : null,
                  ].join(" ")}
                >
                  <textarea
                    maxLength={100}
                    onChange={(value) => this.buscarChangeText(value)}
                    className={[
                      "fpText",
                      textFocus ? "fbtContentOn" : null,
                    ].join(" ")}
                    onFocus={() => this.handleFocusText(true)}
                    onBlur={() => this.handleFocusText(false)}
                  />
                </div>
              </div>
              {/* agree checkbox */}
              <Router>
                <div className={"agreeWrapper"}>
                  <div className={"laText"}>
                    <span className={"normolColor"}>{mxPmAgree}</span>
                    <Link to={terminosUri} target="_blank">
                      <span className={"doc"}>{Servicio}</span>
                    </Link>
                    <span className={"normolColor"} style={{ margin: "0 3px" }}>
                      {agreeAnd}
                    </span>
                    <Link to={privacidadUri} target="_blank">
                      <span className={"doc"}>{Privacidad}</span>
                    </Link>
                  </div>
                  <div
                    className={["laCheck", "hasCheck"].join(" ")}
                    onClick={this.buscarAgreeCheck}
                  >
                    <img
                      className="laImg"
                      src={agreeCheck ? delCheck : delCheckBox}
                      alt="s"
                    />
                  </div>
                </div>
              </Router>
              {!isMobile && (
                <div
                  className={[
                    "fbtAction",
                    textPhone && !idCardError && agreeCheck && !message
                      ? "fbtActionOn"
                      : null,
                  ].join(" ")}
                  onClick={() => this.handleSumit()}
                >
                  <div className={"fbtDo"}>{submit}</div>
                </div>
              )}
              {isMobile && (
                <div
                  className={[
                    "fbtActionMobile",
                    textPhone && !idCardError && agreeCheck && !message
                      ? "fbtActionOn"
                      : null,
                  ].join(" ")}
                  onClick={() => this.handleSumit()}
                >
                  <div className={"fbtDo"}>{submit}</div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className={"fbtSuccess"}>
            <span>{result}</span>
          </div>
        )}

        {showError && (
          <div className={["errorModal"].join(" ")}>
            <div
              className={["errorContent", !errorMsg && "loadingContent"].join(
                " "
              )}
            >
              {errorMsg ? (
                <div
                  className={[
                    "errorHeader",
                    isMobile && "errorHeader-mob",
                  ].join(" ")}
                >
                  ×
                </div>
              ) : (
                <div
                  className={["loader", isMobile && "loader-mob"].join(" ")}
                />
              )}
              <div
                className={[
                  "errorMsg",
                  isMobile && "errorMsg-mob",
                  !errorMsg && "loadingMsg",
                ].join(" ")}
              >
                {errorMsg || loadingText}
              </div>
              {errorMsg && (
                <div
                  onClick={() => this.handleOK()}
                  className={["btn", isMobile && "btn-mob"].join(" ")}
                >
                  {okText}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DeleteUser;
