import React, { Component } from "react";
import Home from "./Puerta";
import Politica from "./Page/Suyo/politica";
import Terminos from "./Page/Suyo/terminos";
import Datos from "./Page/Suyo/datos";
import ErrorInfo from "./Page/ErrorInfo";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory();
class App extends Component {
  render() {
    console.log("----App----");
    return (
      <Router history={browserHistory}>
        <Route exact path="/" component={Home} />
        <Route exact path="/pmPolitica" component={Politica} />
        <Route exact path="/pmTerminos" component={Terminos} />
        <Route exact path="/errorInfo" component={ErrorInfo} />
        <Route exact path="/suyoDatos" component={Datos} />
      </Router>
    );
  }
}

export default App;
