import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Link } from "react-router-dom";

import { Element } from "rc-scroll-anim";
import email from "../assets/imgs/email.png";
import homeBg from "../assets/imgs/homeBg.png";
import logo from "../assets/imgs/logo.png";
class Banner extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    navToShadow: PropTypes.func,
  };
  static defaultProps = {
    className: "banner",
  };
  constructor(props) {
    super(props);
    this.state = {
      appUrl: "",
    };
  }
  componentDidMount() {
    console.log(window.location.origin);
    fetch(window.location.origin + "/nuestraApl", {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=UTF-8" },
      mode: "no-cors",
      cache: "default",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.ret === 0) {
          const { data = {} } = res;
          this.setState({
            appUrl: data.url,
          });
        }
      });
  }

  render() {
    const { className, navToShadow, isMobile } = this.props;
    const { appUrl } = this.state;
    const title = "¡Le ayudamos a  obtener un préstamo más fácilmente!";
    const subtitle =
      "En 4 pasos sencillos, obtenga su dinero en un plazo de 2 a 24 horas.";
    const desc1 = "Registrarse";
    const desc2 = "enviar información";
    const desc3 = " solicitar";
    const desc4 = " retirarse";
    const Footer = () => {
      // const title1 = "Contáctanos";
      const title2 = "Regulaciones";
      const privacidad = "< política de privacidad >";
      const terminos = "< Contrato de Usuario >";
      const emailUrl = "servicio@prestman.tech";
      const privacidadUri = "/pmPolitica";
      const terminosUri = "/pmTerminos";

      if (isMobile) {
        return (
          <footer id="footer" className="footer-mobile">
            <div className="one-center">
              <div className="title">{title2}</div>

              <Router>
                <div className="infos">
                  <Link to={privacidadUri} target="_blank">
                    {privacidad}
                  </Link>
                </div>
                <div className="infos">
                  <Link to={terminosUri} target="_blank">
                    {terminos}
                  </Link>
                </div>
              </Router>
            </div>
            <div className="one-center-end">
              <div className="infos">
                <div className="email">
                  <img src={email} alt="email" />
                  {emailUrl}
                </div>
              </div>
            </div>
          </footer>
        );
      }
      return (
        <div id="footer" className={"footer"}>
          <div className="bar">
            <div className="barContent">
              <div className="one-center">
                <div className="infos">
                  <div className="email">
                    <img src={email} alt="email" />
                    {emailUrl}
                  </div>
                </div>
              </div>
              <div className="one-center">
                <div className="title">{title2}</div>
                <Router>
                  <div className="infos">
                    <Link to={privacidadUri} target="_blank">
                      <div>{privacidad}</div>
                    </Link>
                    <Link to={terminosUri} target="_blank">
                      <div className="linkEnd">{terminos}</div>
                    </Link>
                  </div>
                </Router>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <Element
        id="home"
        component="section"
        className={isMobile ? "block-mobile " : "block"}
        onChange={navToShadow}
      >
        <div className={className + (isMobile ? "-mobile " : "")}>
          <img alt="homeBg" className={`homeBg`} src={homeBg} />
          {isMobile ? (
            <div className="homeHeader">
              {" "}
              <img alt="logo" className={`logo`} src={logo} />
            </div>
          ) : (
            <img alt="logo" className={`logo`} src={logo} />
          )}

          <div className={`content`}>
            <div className={isMobile ? "title-mobile" : "title"}>{title}</div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={appUrl}
              key="a"
              className={`word-download`}
            >
              Descargar PrestMan
            </a>
            <div className={`subtitle`}>{subtitle}</div>
            <div className="newDesc">
              <div className={`desc desc1`}>{desc1}</div>
              <div className={`line`}>{isMobile ? "⬇" : "  •  "}</div>
              <div className={`desc desc2`}>{desc2}</div>
              <div className={`line`}>{isMobile ? "⬇" : "  •  "}</div>
              <div className={`desc desc3`}>{desc3}</div>
              <div className={`line`}>{isMobile ? "⬇" : "  •  "}</div>
              <div className={`desc desc4`}>{desc4}</div>
            </div>
          </div>
          <Footer />
        </div>
      </Element>
    );
  }
}

export default Banner;
